import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../../../../core/Components/Link';

const MobileToolbar = ({
  fields,
  handleMenuClick,
  handleSearchClick,
  isSearching,
  showMobileMenu,
}) => {
  return (
    <nav id="mobile-toolbar-nav" className="mobile-toolbar">
      <ul className="cf">
        <li>
          <button type="button" onClick={handleMenuClick} className="menu">
            <Text field={fields.menuText} />
          </button>
        </li>
        <li>
          <Link
            field={fields.contactLink}
            className="contact"
            tabIndex={showMobileMenu ? -1 : 0}
          />
        </li>
        <li>
          <Link
            field={fields.clinicFinderLink}
            className="clinic"
            tabIndex={showMobileMenu ? -1 : 0}
          />
        </li>
        <li>
          <button
            type="button"
            onClick={handleSearchClick}
            className={`search ${isSearching ? 'active' : ''}`}
            tabIndex={showMobileMenu ? -1 : 0}
          >
            <Text field={fields.searchText} />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default MobileToolbar;
