import React from "react";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import axios from "axios";
import jwtDecode from "jwt-decode";
import ListItem from "../ListItem/ListItem";

function getMinutes(minutes) {
  return new Date(new Date().getTime() + minutes * 60 * 1000);
}

// pass the store logout url domain and determine if it is SIT, UAT or other from that.
// used to get the correct country casing and auth token
function getEnv(store) {
  let env = "";
  if (store && store.includes("sandbox")) {
    env = "SIT";
  } else if (store && store.includes("www2")) {
    env = "UAT";
  } else {
    env = "";
  }
  return env;
}

// get appropriate store country for user based on user country
function storeLogoutCountryCode(countryCode) {
  let code = "";
  switch (countryCode) {
    case "PR":
      code = "US";
      break;
    case "NZ":
      code = "AU";
      break;
    case "GB":
    case "IE":
      code = "UK";
      break;
    default:
      code = countryCode;
  }
  return code;
}

// token used to call store logout endpoint
function getToken(env) {
  let token = "";
  switch (env) {
    case "SIT":
      token = "c3RvcmVmcm9udDpHUytFVzIxLUdyZWF0QzBjaGxlYXI=";
      break;
    case "UAT":
      token = "ZGV2czpncmVlbmF2b2NhZG8=";
      break;
    default:
      token = "";
  }
  return token;
}

class ProfileDropdown extends React.Component {
  static updateUserToken(token, cookieDomain) {
    // update cookie
    cookie.save("currentUser", token, {
      domain: cookieDomain,
      secure: true,
      path: "/",
      expires: getMinutes(120),
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      profileLoaded: false,
      profileData: {},
      profileDataError: false,
      firstName: "",
      lastName: "",
      countryCode: "",
      locale: "",
      name: "",
      userType: "",
      personaType: "",
      globalStoreDisabled: false,
    };

    this.toggleClass = this.toggleClass.bind(this);
    this.toggleFeatures = this.toggleFeatures.bind(this);
    this.closePopOver = this.closePopOver.bind(this);
    this.onSetResult = this.onSetResult.bind(this);
    this.onErrorResult = this.onErrorResult.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    const token = cookie.load("currentUser");
    const {
      authUHDropdownMenu,
      cookieDomain,
      authAuthorize,
      urlLang,
      scApiKey,
      authToken,
      drxMain,
    } = this.props;

    const GQL_URL = `${drxMain}/api/cochlear?sc_apikey=${scApiKey}&sc_site=drx-us-en`;
    const paramLng = urlLang || "en";

    // get persona from cookie
    const currentUserAttributes = cookie.load("currentUserAttributes");

    // get the personas array from cookie value
    // convert all values to lowercase
    const personas = currentUserAttributes?.personas.map((i) =>
      i.toLowerCase(),
    );

    // using the array, return a user type
    let personaType = "";
    if (personas) {
      if (personas.includes("carer") && personas.includes("recipient")) {
        personaType = "carer-recipient";
      } else if (personas.includes("carer")) {
        personaType = "carer";
      } else {
        personaType = "recipient";
      }
    }

    this.setState({
      personaType,
    });

    // Make a request for a user with a given ID
    const decoded = token && jwtDecode(token);

    if (decoded) {
      const name = decoded.name;
      const firstName = decoded.given_name;
      const lastName = decoded.family_name;
      const userType = decoded["https://www.cochlear.com/user_type"];
      const countryCode = decoded["https://www.cochlear.com/country_code"];
      const locale = decoded.locale;

      this.setState({
        firstName,
        lastName,
        name,
        userType,
        countryCode,
      });

      const id = decoded["https://www.cochlear.com/cochlear_id"];
      axios
        .get(`${authUHDropdownMenu}?id=${id}&app=drx&lng=${paramLng}`, {
          params: {},
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          if (this._isMounted) {
            // from returned response
            const newToken = result.headers["x-amzn-remapped-authorization"];
            if (newToken) {
              ProfileDropdown.updateUserToken(
                newToken,
                this.props.cookieDomain,
              );
            }
            this.onSetResult(result);
          }
        })
        .catch((error) => {
          if (this._isMounted) {
            const response = error.response;

            if (response) {
              if ([401, 403].indexOf(response.status) !== -1) {
                // session has expired.. clear the session
                // Remove the current user and access token
                cookie.remove("currentUser", {
                  path: "/",
                  domain: cookieDomain,
                });
                cookie.remove("accessToken", {
                  path: "/",
                  domain: cookieDomain,
                });
                // Store the post-login redirect URL in a cookie to be consumed by the auth-application to handle the redirect
                // back to the application
                cookie.save("referrerUrl", window.location.href, {
                  path: "/",
                  domain: cookieDomain,
                  secure: true,
                  expires: getMinutes(15),
                });

                // Send to reauthenticate
                window.location.replace(authAuthorize);
              }
            }

            this.onErrorResult(error);
          }
        });

      // Check if data is available in session storage
      const globalStoreData = sessionStorage.getItem("DRX__GS");

      if (globalStoreData) {
        // Parse and set data from session storage if available
        this.setState({
          globalStoreDisabled: JSON.parse(globalStoreData),
        });
      } else {
        // If not in session storage, make a GraphQL call to fetch data with axios
        this.fetchData(countryCode, locale, authToken, GQL_URL);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchData(countryCode, locale, authToken, GQL_URL) {
    const code = storeLogoutCountryCode(countryCode);
    const drxCountryCode = code !== "UK" ? code.toUpperCase() : "GB";
    const languageCode = locale.substring(0, 2).toLowerCase();
    const drxLanguageCode =
      languageCode !== "en"
        ? `${languageCode}-${drxCountryCode}`
        : languageCode;

    // GQL query to return the screen image/message and button labels
    const QUERY_DATA = JSON.stringify({
      query: `
      {
        item(path: "/sitecore/content/drx/${drxCountryCode}/Configuration/Header/DRX Header", language: "${drxLanguageCode}") {
          disableGSLogout: field(name: "disableGSLogout") {
            ... on CheckboxField {
              value: boolValue
            }
          }
        }
      }
        
    `,
    });
    axios
      .post(GQL_URL, QUERY_DATA, {
        headers: {
          Authorization: authToken && `Basic ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (this._isMounted) {
          const data = response.data?.data?.item?.disableGSLogout?.value;

          this.setState({
            globalStoreDisabled: data,
          });

          // Store data in session storage
          sessionStorage.setItem("DRX__GS", JSON.stringify(data));
        }
      })

      .catch((error) => {
        if (this._isMounted) {
          // handle error. No error handling required for this check
          // console.log('error, unavailable: ', error);
        }
      });
  }

  onSetResult(result) {
    const token = cookie.load("currentUser");
    const decoded = token && jwtDecode(token);
    this.setState(
      {
        profileLoaded: true,
        profileData: result.data.data.uhmenu,
        countryCode: decoded["https://www.cochlear.com/country_code"],
        locale: decoded.locale,
      },
      () => this.handlePopOver(),
    );
  }

  onErrorResult(error) {
    const token = cookie.load("currentUser");
    const decoded = token && jwtDecode(token);
    this.setState({
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      countryCode: decoded["https://www.cochlear.com/country_code"],
      locale: decoded.locale,
      profileDataError: !!error,
    });
  }

  handlePopOver() {
    const { profileLoaded, profileData } = this.state;
    const { callbackWhatsNewContent, callbackShowPopOver } = this.props;

    // pass back whats new content
    callbackWhatsNewContent(profileData.whatsnew);
    const { toggle, version } = profileData.whatsnew;
    const popOver = cookie.load("whatsnew");
    // cookie doesnt exist, lets show the popover
    if (profileLoaded && !popOver) {
      return toggle === "on" && callbackShowPopOver(true);
    } else if (profileLoaded && version > popOver) {
      // if the version in the api is greater than the value stored in the cookie
      return toggle === "on" && callbackShowPopOver(true);
    } else {
      // user has the latest version and has already dismissed it.
      return null;
    }
  }

  toggleClass() {
    // call parent function to update prop
    this.props.callbackClassDropdownNav();
  }

  toggleFeatures() {
    // call parent function to update prop
    this.props.callbackWhatsNew();
  }

  closePopOver() {
    // save version to cookie
    cookie.save("whatsnew", this.state.profileData.whatsnew.version, {
      path: "/",
      domain: this.props.cookieDomain,
      secure: true,
    });
    this.props.callbackShowPopOver(false);
  }

  handleLogout() {
    this.setState({
      logout: true,
    });

    const { storeLogout, authLogout, authorizeApp, authorizeAppAuthorizePath } =
      this.props;
    const { globalStoreDisabled } = this.state;

    const token = cookie.load("currentUser");
    const currentPage = window.location.href;
    const logoutUrl = `${authorizeApp}${authorizeAppAuthorizePath}?request=logout`;

    if (token) {
      const decoded = jwtDecode(token);
      const app = decoded["https://www.cochlear.com/app"];
      const countryCode = decoded["https://www.cochlear.com/country_code"];
      const locale = decoded.locale;
      const languageCode = locale.substring(0, 2).toLowerCase();
      const code = storeLogoutCountryCode(countryCode);
      const storeLogoutEndPath = "shop/storeLogout";
      const check = app.includes("store");
      // remove http protocol from origin
      const origin = currentPage.replace(/^https?:\/\//i, "");
      const chainLogout = encodeURIComponent(logoutUrl);

      // Clear session storage on logout
      sessionStorage.removeItem("DRX__GS");

      // depending on env we might not have a store logout eg DEV
      if (check && storeLogout && !globalStoreDisabled) {
        // store logout check

        // configure store logout to do a preflight check on UAT & PRD only
        // this check will confirm the env is up before redirecting the user there
        // if it is not available, skip the store logout redirection

        const env = getEnv(storeLogout);
        const storeLogoutCompiledPath = `${storeLogout}${
          env !== "SIT" ? code.toLowerCase() : code
        }/${languageCode}/${storeLogoutEndPath}`;

        const authToken = getToken(env);

        const tokenConfig = authToken && {
          headers: {
            Authorization: `Basic ${authToken}`,
          },
        };

        // Make a request for a user with a given ID
        axios
          .options(storeLogoutCompiledPath, tokenConfig)
          .then((response) => {
            // handle success
            console.log("response, available: ", response);
            // token revoke and SFlogout will take place on the authorizer
            window.location.replace(
              `${storeLogoutCompiledPath}?chainLogout=${chainLogout}&origin=${origin}`,
            );
            return false;
          })
          .catch((error) => {
            // handle error
            console.log("error, unavailable: ", error);
            this.logoutUser(currentPage, logoutUrl);
          });
        return false;
      }

      // not a store claim user
      this.logoutUser(currentPage, logoutUrl);
      return false;
    } // End if token

    console.log("okay, tried everything else, just log the user out");
    // catch all log out

    // logout user without token
    window.location.replace(`${authLogout}?logoutURL=${currentPage}`);
  }

  // logout user with token
  // -----------------------
  // revoke token and logout - user has a token but no store claim so log them out after revoking their token
  // or the store endpoint was down
  logoutUser = (currentPage, logoutUrl) => {
    const { cookieDomain } = this.props;
    if (currentPage) {
      // Store the post-logout redirect URL in a cookie to be consumed by the auth-application to handle the redirect
      // back to the application
      cookie.save("referrerUrl", currentPage, {
        path: "/",
        domain: cookieDomain,
        secure: true,
        expires: getMinutes(15),
      });
    }
    window.location.replace(logoutUrl);
  };

  render() {
    const {
      profileData,
      profileLoaded,
      logout,
      firstName,
      lastName,
      profileDataError,
      name,
      userType,
      personaType,
    } = this.state;
    const {
      isActiveDropdownNav,
      showPopOver,
      cochlearMCR,
      drxMain,
      showProfileLogo,
      showGivenNameLast,
    } = this.props;
    const {
      dropDownList,
      logoutLabel,
      initial,
      whatsnew,
      recipientText,
      carerText,
      recipientCarerText,
    } = profileData;

    // get text based on user type
    const getUserType = (userType) => {
      const userTypeTexts = {
        carer: carerText,
        "carer-recipient": recipientCarerText,
      };

      const type = userType ? userType.toLowerCase() : null;
      return userTypeTexts[type] || recipientText;
    };

    return (
      <div className={`profile-dropdown ${isActiveDropdownNav ? "show" : ""}`}>
        <button
          type="button"
          id="profileDropdownMenuButton"
          aria-haspopup="true"
          aria-expanded={isActiveDropdownNav ? "true" : "false"}
          className="profile-dropdown__toggle"
          onClick={this.toggleClass}
        >
          {/* Show user ititials or the cochlear logo */}
          {showProfileLogo ? (
            <span className="profile-dropdown__toggle__circle">
              <img
                src="https://mss-p-007-delivery.stylelabs.cloud/api/public/content/46a7ac851fb24ab98378458c15106c03?v=a7e02268"
                alt="cochlear brandmark"
              />
            </span>
          ) : (
            <span className="profile-dropdown__toggle__circle">
              {profileDataError
                ? `${firstName.charAt(0)}${lastName.charAt(0)}`
                : initial}
            </span>
          )}
        </button>
        {showPopOver && whatsnew && (
          <div className="popover">
            <div className="popover__container">
              <button
                type="button"
                className="button button--popover"
                /* launches modal and closes popover */
                // eslint-disable-next-line arrow-parens
                onClick={() => {
                  this.toggleFeatures();
                  this.closePopOver();
                }}
              >
                {whatsnew.popup.label}
              </button>
              <button
                type="button"
                className="button button--dismiss"
                onClick={this.closePopOver}
              >
                {whatsnew.popup.close}
              </button>
            </div>
          </div>
        )}
        <div
          className="profile-dropdown__menu"
          aria-labelledby="profileDropdownMenuButton"
        >
          <div className="profile-dropdown__menu__item profile-dropdown__menu__item--header">
            {!profileDataError &&
              (showGivenNameLast ? (
                <p className="subtitle">
                  {lastName}・{firstName}・{getUserType(personaType) || ""}
                </p>
              ) : (
                <React.Fragment>
                  <p className="subtitle">{name}</p>
                  <p className="account">{getUserType(personaType) || ""}</p>
                </React.Fragment>
              ))}
          </div>
          {/* Loop through dropdown list items */}
          {profileLoaded &&
            dropDownList.map((link, index) => {
              const linksLength = dropDownList.filter(
                (link) => link.url !== "",
              ).length;

              if (link.url) {
                const isMCR = link.url.includes("/wps/myportal/myrecipient/");
                return (
                  <React.Fragment key={link.url}>
                    <ListItem
                      icon={link.icon}
                      target={link.target}
                      label={link.label}
                      path={`${isMCR ? cochlearMCR : drxMain}${link.url}`}
                    />
                    {index === linksLength - 1 && (
                      <button
                        type="button"
                        onClick={this.handleLogout}
                        disabled={logout}
                        className={`profile-dropdown__menu__item profile-dropdown__menu__item--logout has-spinner ${
                          logout ? "is-submitting" : ""
                        }`}
                      >
                        {logoutLabel || "Log out"}
                        {/* is submitting spinner  */}
                        <span className="lds-ring">
                          <span />
                          <span />
                          <span />
                          <span />
                        </span>
                      </button>
                    )}
                  </React.Fragment>
                );
              } else if (profileData.whatsnew.toggle === "on") {
                // if url doesnt exist, it is the new feature link

                return (
                  <button
                    key={link.label}
                    type="button"
                    className="profile-dropdown__menu__item profile-dropdown__menu__item--new-feature"
                    // onClick={this.toggleFeatures}
                    onClick={() => {
                      this.toggleFeatures();
                      this.toggleClass();
                    }}
                  >
                    {link.label}
                  </button>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    );
  }
}

ProfileDropdown.defaultProps = {
  isActiveDropdownNav: false,
  showPopOver: false,
};

ProfileDropdown.propTypes = {
  callbackWhatsNewContent: PropTypes.func.isRequired,
  callbackWhatsNew: PropTypes.func.isRequired,
  callbackShowPopOver: PropTypes.func.isRequired,
  callbackClassDropdownNav: PropTypes.func.isRequired,
  isActiveDropdownNav: PropTypes.bool,
  showPopOver: PropTypes.bool,
};

export default ProfileDropdown;
