import React from "react";
import cookie from "react-cookies";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import ImageLink from "../../../../../core/Components/ImageLink";

function getMinutes(minutes) {
  return new Date(new Date().getTime() + minutes * 60 * 1000);
}

const MobileHeader = ({
  fields,
  urlLang,
  showMobileMenu,
  isActiveDropdownNav,
  showPopOver,
  isProfileEnabled,
  isLoginEnabled,
  isUserLoggedIn,
  loginPath,
  callbackClassDropdownNav,
  callbackWhatsNew,
  callbackWhatsNewContent,
  callbackShowPopOver,
}) => {
  return (
    <div
      className={`mobile-header ${showMobileMenu ? "is-hidden" : ""}  ${
        isActiveDropdownNav ? "is-active" : ""
      }`}
    >
      <div className="mobile-header__item" />
      <div className="mobile-header__item">
        <span className="mobile-logo">
          <ImageLink
            imageField={fields.mobileLogo}
            linkField={fields.logoLink}
          />
        </span>
      </div>
      <div className="mobile-header__item">
        {isLoginEnabled &&
          (isProfileEnabled && isUserLoggedIn ? (
            <ProfileDropdown
              cookieDomain={fields.COCHLEAR_COOKIE_DOMAIN.value}
              authUHDropdownMenu={fields.AUTH_UH_DROPDOWN_MENU.value}
              authRevoke={fields.AUTH_REVOKE.value}
              authAuthorize={fields.AUTH_AUTHORIZE.value}
              authLogout={fields.AUTH_CIM_RECIPIENT_LOGOUT.value}
              drxMain={fields.COCHLEAR_DRX_MAIN.value}
              storeLogout={fields.STORE_LOGOUT.value}
              cochlearMCR={fields.COCHLEAR_MCR.value}
              authorizeApp={fields.AUTHORIZE_USER_APP.value}
              authorizeAppAuthorizePath={
                fields.AUTHORIZE_USER_APP_AUTHORIZE_PATH.value
              }
              scApiKey={fields.SC_API_KEY.value}
              authToken={fields.AUTH_TOKEN.value}
              showProfileLogo={fields.showProfileLogo.value}
              showGivenNameLast={fields.showGivenNameLast.value}
              isActiveDropdownNav={isActiveDropdownNav}
              showPopOver={showPopOver}
              callbackClassDropdownNav={callbackClassDropdownNav}
              callbackWhatsNew={callbackWhatsNew}
              callbackWhatsNewContent={callbackWhatsNewContent}
              callbackShowPopOver={callbackShowPopOver}
              urlLang={urlLang}
            />
          ) : (
            <a
              onClick={() => {
                // Set referrerUrl as a cookie as it needs to be shared across sub domains
                return cookie.save("referrerUrl", window.location.href, {
                  path: "/",
                  domain: fields.COCHLEAR_COOKIE_DOMAIN.value,
                  secure: true,
                  expires: getMinutes(15),
                });
              }}
              href={loginPath}
              className="login-btn"
            >
              <span>{fields.loginLink.value.text}</span>
            </a>
          ))}
      </div>
    </div>
  );
};

export default MobileHeader;
