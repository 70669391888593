import React, { useState, useEffect } from 'react';

/**
 * Show encapsulated content (children) only if visitor is from local geo detected region
 * @param {*} props Property values from calling component
 * @param {string} props.countryCode country code of current site loaded
 * @param {boolean} props.reverse reverse the logic, and show content if visitor is NOT from local geo detected region
 * @param {ReactChild} props.children Encapsulated component to be loaded
 * @returns
 */
const ShowIfGeoMatchesCountry = (props) => {
  const [showContent, setShowContent] = useState(false);
  const { countryCode, children, enabled, reverse } = props;
  const isReverse = reverse !== undefined && reverse === true;
  const onSuccess = (res) => {
    let countryIsoCode = res.country.iso_code.toLowerCase();
    const isSameLocation = countryCode.toLowerCase() === countryIsoCode;

    // Show content if:
    // same location && reverse flag not set
    // or not same location and reverse flag is set
    // else dont show content
    switch (true) {
      case isSameLocation && !isReverse:
      case !isSameLocation && isReverse:
        setShowContent(true);
        break;
      default:
        setShowContent(false);
    }
  };

  const onError = () => {
    return null;
  };

  useEffect(() => {
    if (!!window.geoip2) {
      window.geoip2.country(onSuccess, onError);
    } else {
      onError();
    }
  }, []);

  return (
    <React.Fragment>
      {showContent && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};

export default ShowIfGeoMatchesCountry;
