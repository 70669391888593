import React from 'react';
import {
  Image,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import Link from './Link';

const ImageContentLink = ({ imageField, linkField, className }) => {
  return isExperienceEditorActive() || !linkField.value.href ? (
    <Image field={imageField} className={className} />
  ) : (
    <Link field={linkField}>
      <Image field={imageField} className={className} />
    </Link>
  );
};

export default ImageContentLink;
