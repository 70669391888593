import React from "react";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import Link from "../../../../../core/Components/Link";

class OnScreenNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topNavId: "",
      secondNavId: "",
      finalNavItem: null,
      isAnimatingOut: false,
      menuOpen: false,
    };

    this.filteredNav = this.filterHiddenNavigationItems(
      this.props.fields.additionalData.data.item,
    );
    // this.setMenuShown = this.setMenuShown.bind(this);
  }

  toggleTopNavItem = (id) => {
    const update = () =>
      this.setState((prevState) => {
        return {
          isAnimatingOut: false,
          topNavId: prevState.topNavId === id ? "" : id,
          secondNavId: "",
          finalNavItem: null,
          menuOpen: prevState.topNavId !== id,
        };
      });

    if (this.state.topNavId) {
      this.setState({ isAnimatingOut: true }, () => setTimeout(update, 300));
    } else {
      update();
    }
  };

  toggleSecondNavItem = (item) => {
    const update = () =>
      this.setState((prevState) => {
        return prevState.secondNavId === item.id
          ? {
              isAnimatingOut: false,
              secondNavId: "",
              finalNavItem: null,
            }
          : {
              isAnimatingOut: false,
              secondNavId: item.id,
              finalNavItem: item,
            };
      });

    if (this.state.secondNavId) {
      this.setState({ isAnimatingOut: true }, () => setTimeout(update, 300));
    } else {
      update();
    }
  };

  // setMenuShown = (status = false) => {
  // 	this.setState({menuOpen: status});
  // }

  closeMenu = (e) => {
    e.preventDefault();
    this.setState({
      topNavId: "",
      secondNavId: "",
      finalNavItem: null,
      menuOpen: false,
    });
  };

  handleBack = () => {
    const update = () =>
      this.setState((prevState) => {
        return prevState.secondNavId
          ? {
              secondNavId: "",
              finalNavItem: null,
              isAnimatingOut: false,
            }
          : {
              topNavId: "",
              isAnimatingOut: false,
            };
      });

    if (this.state.secondNavId || this.state.topNavId) {
      this.setState({ isAnimatingOut: true }, () => setTimeout(update, 300));
    } else {
      update();
    }
  };

  getHeaderLinkContainer = (item) => {
    return (
      <a
        href={item.navigationLink.url || item.url}
        className="header-link-container"
      >
        <h3>{item.navigationTitle.value || item.title.value}</h3>
        <div
          className="cta-link"
          aria-label={`${this.props.fields.menuLinkDescription.value} ${
            item.navigationTitle.value || item.title.value
          }`}
        >
          {this.props.fields.menuLinkText.value}
        </div>
      </a>
    );
  };

  filterHiddenNavigationItems = (data) => {
    data.children = data.children
      .filter((child) => !child.hideFromNavigation.boolValue)
      .map((navItem) => {
        navItem.children = navItem.children
          .filter((child) => !child.hideFromNavigation.boolValue)
          .map((childNavItem) => {
            childNavItem.children = childNavItem.children.filter(
              (child) => !child.hideFromNavigation.boolValue,
            );
            return childNavItem;
          });
        return navItem;
      });
    return data;
  };

  filterStringSymbols = (title) => {
    return title.replace(
      /™|®|©|&trade;|&reg;|&copy;|&#8482;|&#174;|&#169;/gi,
      "",
    );
  };

  //Analytics team is expecting linkClick events on dropdown interactions
  //This allows that whilst retaining more accessible markup
  fireSyntheticLinkClick = (e, item) => {
    const link = item.navigationLink.url || item.url;
    const isRelativeLink = !link.includes("http");
    window.dataLayer.push({
      event: "gtm.linkClick",
      "gtm.element": e.target,
      "gtm.elementClasses": e.target.classList.value,
      "gtm.elementUrl": `${isRelativeLink ? window.location.host : ""}${link}`,
    });
  };

  getMenuItem = (item) => {
    const { topNavId, secondNavId, finalNavItem } = this.state;
    const title = this.filterStringSymbols(
      item.navigationTitle.value || item.title.value,
    );
    const link = item.navigationLink.url || item.url;
    const hasChildren = !!item.children && !!item.children.length;
    const isShown =
      topNavId === item.id ||
      (hasChildren &&
        !!secondNavId &&
        !!item.children.find((child) => child.id === secondNavId));

    return (
      <li
        key={item.id}
        className={`nav-item ${isShown ? "nav-item-selected" : ""}`}
      >
        {hasChildren ? (
          <button
            type="button"
            className="nav-item-title nav-item-title-top has-children"
            onClick={(e) => {
              this.fireSyntheticLinkClick(e, item);
              this.props.closeSearch();
              this.toggleTopNavItem(item.id);
              // this.setMenuShown(true);
            }}
            aria-expanded={isShown}
            aria-haspopup
          >
            {this.filterStringSymbols(title)}
          </button>
        ) : (
          <a className="nav-item-title nav-item-title-top" href={link}>
            {this.filterStringSymbols(title)}
          </a>
        )}
        {isShown && (
          <div id="nav-main-1">
            <div className="l-padding">
              <ul className="nav-lvl2-container clearfix">
                <li
                  className={`menu-level-detail ${
                    secondNavId ? "is-hidden" : ""
                  }`}
                >
                  {this.getHeaderLinkContainer(item)}
                  {hasChildren && (
                    <ul
                      className="nav-lvl3-container nav-children"
                      aria-label="Sub-menu"
                    >
                      {item.children.map((child) => {
                        const hasChildren =
                          child.children && !!child.children.length;
                        const childTitle =
                          child.navigationTitle.value || child.title.value;
                        return (
                          <li key={child.id} className="nav-item">
                            {hasChildren ? (
                              <button
                                type="button"
                                className="nav-item-title has-children"
                                onClick={(e) => {
                                  this.fireSyntheticLinkClick(e, child);
                                  this.toggleSecondNavItem(child);
                                }}
                                aria-expanded={finalNavItem === child}
                                aria-haspopup
                              >
                                {this.filterStringSymbols(childTitle)}
                              </button>
                            ) : (
                              <a
                                className="nav-item-title"
                                href={child.navigationLink.url || child.url}
                              >
                                {this.filterStringSymbols(childTitle)}
                              </a>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
                {finalNavItem && (
                  <li className="nav-item-submenu">
                    <div className="detail-content-container">
                      {this.getHeaderLinkContainer(finalNavItem)}
                      <ul
                        className="nav-lvl3-container nav-children"
                        aria-label="Sub-menu"
                      >
                        {finalNavItem.children.map((child) => (
                          <li key={child.id} className="nav-item">
                            <a
                              className="nav-item-title"
                              href={child.navigationLink.url || child.url}
                            >
                              {this.filterStringSymbols(
                                child.navigationTitle.value ||
                                  child.title.value,
                              )}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
                <li>
                  <button
                    type="button"
                    className="nav-item-close"
                    title={this.props.fields.closeText.value}
                    onClick={(e) => this.closeMenu(e)}
                  />
                </li>
              </ul>
            </div>
          </div>
        )}
      </li>
    );
  };

  render() {
    const {
      fields,
      toggleCountrySelector,
      searchOpen,
      isProfessional,
      showMobileMenu,
      isProfileEnabled,
      isUserLoggedIn,
    } = this.props;
    const { topNavId, isAnimatingOut } = this.state;

    //If the search has been opened we need to toggle off the menu
    if (!!searchOpen && !!topNavId && !isAnimatingOut) {
      this.toggleTopNavItem("");
    }

    const offscreenBackgroundClass = `offscreen-background ${
      this.state.menuOpen ? "is-expanded" : ""
    }`;
    return (
      <React.Fragment>
        <ul
          className={`nav cf ${isAnimatingOut ? "animate-out" : ""} ${
            showMobileMenu ? "" : "is-hidden"
          } ${topNavId ? "is-hidden" : ""}`}
        >
          <li className={`nav-item nav-item-top ${topNavId ? "open" : ""}`}>
            {topNavId ? (
              <button
                className="nav-item-back"
                type="button"
                onClick={this.handleBack}
              >
                {fields.backText.value}
              </button>
            ) : (
              <div role="presentation" className="nav-item-menu">
                {fields.menuText.value}
              </div>
            )}
            <button
              className="nav-item-close"
              type="button"
              onClick={this.props.handleMobileClose}
            >
              {fields.closeText.value}
            </button>
          </li>
          {!!this.props.handleMobileClose ? (
            <React.Fragment>
              <li className="nav-item">
                <a
                  href={
                    isProfessional
                      ? fields.professionalsLink.value.href
                      : fields.homeLink.value.href
                  }
                  className="nav-item-title nav-item-title-top"
                >
                  {fields.homeLink.value.text}
                </a>
              </li>
              {this.filteredNav.children.map((child) =>
                this.getMenuItem(child, 1),
              )}
              <li className="additional-list">
                <ul>
                  {this.props.fields.additionalList.map(({ fields, id }) => (
                    <li key={id}>
                      <Link field={fields.link} />
                    </li>
                  ))}
                </ul>
              </li>
              {this.props.showLanguageDropdown ? (
                <LanguageDropdown
                  fields={fields}
                  toggleCountrySelector={toggleCountrySelector}
                  isProfileEnabled={isProfileEnabled}
                  isUserLoggedIn={isUserLoggedIn}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.filteredNav.children.map((child) =>
                this.getMenuItem(child, 1),
              )}
            </React.Fragment>
          )}
        </ul>
        {/* Background overlay */}
        <div
          role="button"
          tabIndex="0"
          className={offscreenBackgroundClass}
          onClick={(e) => {
            this.closeMenu(e);
          }}
        ></div>
      </React.Fragment>
    );
  }
}

export default OnScreenNav;
