import React from "react";
import detectIt from "detect-it";
import cookie from "react-cookies";
import jwtDecode from "jwt-decode";
import { withRouter } from "react-router-dom";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ImageLink from "../../../core/Components/ImageLink";
import BindEnterAndSpaceToClick from "../../../core/Utils/BindEnterAndSpaceToClick";
import MessageBanner from "./Components/MessageBanner/MessageBanner";
import CountryRedirect from "./Components/CountryRedirect/CountryRedirect";
import CountrySelector from "./Components/CountrySelector/CountrySelector";
import MobileHeader from "./Components/MobileHeader/MobileHeader";
import TopNav from "./Components/TopNav/TopNav";
import MobileToolbar from "./Components/MobileToolbar/MobileToolbar";
import OnScreenNav from "./Components/OnScreenNav/OnScreenNav";
import Search from "./Components/Search/Search";
import DialogPopup from "./Components/DialogPopup/DialogPopup";
import ModalGlobalComponent from "./Components/ModalGlobalComponent/ModalGlobalComponent";
import ModalNewFeatures from "./Components/ModalNewFeatures/ModalNewFeatures";
import NotAuthorized from "./../NotAuthorized/NotAuthorized";
import ShowIfGeoMatchesCountry from "./../../../core/Utils/ShowIfGeoMatchesCountry";
import { defaults, types } from "./Header.props";
import cochlearCountry from "./../../../utils/cochlearCountry.js";
import updateStoreLinks from "./../../../utils/updateStoreLinks.js";
import { withHeaderContext } from "../../../HeaderContext.js";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: null,
      overlayDisclaimer: null,
      overlayClick: () => this.setOverlay(),
      overlayDisclaimerClick: () => this.setOverlayDisclaimer(),
      sticky: false,
      isSearching: false,
      showCountryDropdown: false,
      isActiveDropdownNav: false,
      showFeaturesModal: false,
      featuresModalContent: {},
      profileLoaded: false,
      showPopOver: false,
      supportedCountry: true,
      currentCountry: "",
      homePath: "/",
      tokenUserCountryCode: "",
      tokenUserLocale: "",
    };
    this.navRef = React.createRef();

    this.toggleClassDropdownNav = this.toggleClassDropdownNav.bind(this);
    this.toggleFeaturesModal = this.toggleFeaturesModal.bind(this);
    this.setWhatsNewContent = this.setWhatsNewContent.bind(this);
    this.toggleWhatsNewPopOver = this.toggleWhatsNewPopOver.bind(this);
    this.handleDisclaimerContinue = this.handleDisclaimerContinue.bind(this);
    this.handleDisclaimerCountrySelect =
      this.handleDisclaimerCountrySelect.bind(this);
  }

  componentDidMount() {
    const { fields, headerContext, sitecoreContext } = this.props;
    const {
      // CHAT config
      ChatDisplay,
      chatType,
      ChatRecipientOnly,
      ChatAuthOnly,
      chatFixedPagePaths,
      ChatFixedPageSetup,
      // MIAW Chat
      language,
      orgId,
      embeddedServiceAPIName,
      siteUrl,
      scrtUrl,
      // SCFC Chat
      sfEnv,
      buttonId,
      buttonDeployId,
      buttonChatName,
      buttonEswLiveAgentDevName,
      salesforceId,
      baseLiveAgentContentURL,
      baseLiveAgentURL,
      isOfflineSupportEnabled,
    } = fields;

    const isProfessional = sitecoreContext.route.fields.isProfessional.value;

    // pass to layout
    const chatConfig = {
      ChatDisplay,
      chatType,
      ChatRecipientOnly,
      ChatAuthOnly,
      chatFixedPagePaths,
      ChatFixedPageSetup,
    };
    const miawChat = {
      language,
      orgId,
      embeddedServiceAPIName,
      siteUrl,
      scrtUrl,
    };
    const sfhcChat = {
      sfEnv,
      buttonId,
      buttonDeployId,
      buttonChatName,
      buttonEswLiveAgentDevName,
      salesforceId,
      baseLiveAgentContentURL,
      baseLiveAgentURL,
      isOfflineSupportEnabled,
    };

    headerContext.handleLoadChatFields({ chatConfig, miawChat, sfhcChat });

    window.addEventListener(
      "scroll",
      this.handleScroll,
      detectIt.passiveEvents ? { passive: true } : false,
    );

    // get cookie
    const getUser = cookie.load("currentUser");
    const decoded = getUser && jwtDecode(getUser);
    const appAccess = decoded && decoded["https://www.cochlear.com/app"];
    const locale = decoded && decoded.locale;
    const userId = decoded && decoded["https://www.cochlear.com/cochlear_id"];

    // handles country not supported for logged in users
    const countryCode =
      decoded && decoded["https://www.cochlear.com/country_code"];
    const currentRootUrl = this.props.fields.rootItem.url;
    const currentCountry = currentRootUrl.substring(1, 3);

    const getCochlearCountryInfo = cochlearCountry(countryCode);
    const cochlearCode = getCochlearCountryInfo.code;
    const cochlearHome = getCochlearCountryInfo.home;

    const supportedCountry =
      countryCode &&
      currentCountry.toUpperCase() === cochlearCode.toUpperCase();

    if (appAccess && appAccess.includes("uh")) {
      this.setState({
        currentCountry: currentCountry,
        supportedCountry: supportedCountry,
        homePath: cochlearHome,
        tokenUserCountryCode: countryCode,
        tokenUserLocale: locale,
        profileLoaded: true,
      });

      headerContext.setUserId(userId);

      // user will be presented with NotAuthorized component
      if (!supportedCountry) {
        return false;
      }
    }

    this.setSessionCookie();

    // professionals popup
    if (fields.popupShowDialog && fields.popupShowDialog.value) {
      if (isProfessional) {
        if (!cookie.load("professional")) {
          document.body.style["overflow-y"] = "hidden";
          document.body.style.height = "100vh";
          this.setState({
            overlayClick: () => this.closeDialogPopup(true),
          });
          this.setOverlay(
            <DialogPopup
              fields={fields}
              closePopup={this.closeDialogPopup}
              redirectUrl={true}
              cookieName="professional"
            />,
          );
        }
      } else if (!cookie.load("prodAccDialog")) {
        const redirectUrl =
          fields.popupRedirectUrl && fields.popupRedirectUrl.value.href
            ? fields.popupRedirectUrl.value.href
            : false;
        document.body.style["overflow-y"] = "hidden";
        document.body.style.height = "100vh";
        this.setState({
          overlayClick: () => this.closeDialogPopup(redirectUrl),
        });
        this.setOverlay(
          <DialogPopup
            fields={fields}
            closePopup={this.closeDialogPopup}
            redirectUrl={redirectUrl}
            cookieName="prodAccDialog"
          />,
        );
      }
    }

    // content disclaimer popup (DBR-7819)
    if (sitecoreContext.metadata.contentDisclaimerShow === "1") {
      // Sitecore modal values
      // Tech debt:
      // Currently the values of 2 buttons are stored statically in Sitecore modal fields.
      // Would like to change to having buttons as sitecore child items of sitecore modal object
      // Button items would have different types with dedicated functions (close, redirect, country selector, etc)
      const disclaimerData = {
        contentDisclaimerDataSource: {
          fields: sitecoreContext.metadata.contentDisclaimerDataSource,
        },
      };

      // Front end modal settings
      const disclaimerModalObject = {
        actions: {
          handlePrimary: this.handleDisclaimerCountrySelect,
          handleSecondary: this.handleDisclaimerContinue,
          handleClose: this.closeDialogDisclaimerPopup,
        },
        fields: {
          popupStyle:
            disclaimerData.contentDisclaimerDataSource.fields.modalStyle,
          popupTitle: { value: "" },
          popupDescription: {
            value:
              disclaimerData.contentDisclaimerDataSource.fields.modalMessage,
          },
          popupPrimary: {
            value:
              disclaimerData.contentDisclaimerDataSource.fields.option1Text,
          },
          popupSecondary: {
            value:
              disclaimerData.contentDisclaimerDataSource.fields.option2Text,
          },
          popupCloseAria: { value: "" },
          popupShowDialog: { value: true },
        },
      };

      if (
        sitecoreContext.metadata.contentDisclaimerShow === "1" &&
        !cookie.load("disclaimer")
      ) {
        this.setState({
          setOverlayDisclaimer: () => this.closeDialogDisclaimerPopup(true),
        });
        this.setOverlayDisclaimer(
          <ShowIfGeoMatchesCountry
            countryCode={cochlearCountry(currentCountry.toUpperCase()).code}
            reverse
          >
            <div
              onClick={this.state.overlayDisclaimerClick}
              onKeyDown={BindEnterAndSpaceToClick}
              role="button"
              tabIndex="0"
              className="offscreen-background popup-disclaimer is-expanded"
            />
            <ModalGlobalComponent
              fields={disclaimerModalObject.fields}
              actions={disclaimerModalObject.actions}
            />
          </ShowIfGeoMatchesCountry>,
        );
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      "scroll",
      this.handleScroll,
      detectIt.passiveEvents ? { passive: true } : false,
    );
  }

  setSessionCookie = () => {
    const session = cookie.load("cochlearSession");
    let allowSessionCookie = false;
    if (!session) {
      const OptanonConsent = cookie.load("OptanonConsent");

      // Check OneTrust OptanonConsent exists
      if (OptanonConsent) {
        // Check OneTrust OptanonConsent has C0002 or C0003 selected
        OptanonConsent.includes("C0002:1") || OptanonConsent.includes("C0003:1")
          ? (allowSessionCookie = true)
          : (allowSessionCookie = false);
      } else {
        allowSessionCookie = true;
      }

      if (allowSessionCookie) {
        //UUIDV4 function
        const sessionId = "x-y-y-x".replace(/[x,y]/g, (c) =>
          Math.random()
            .toString(16)
            .substring(c === "x" ? 7 : 4),
        );
        cookie.save("cochlearSession", sessionId, {
          path: "/",
          secure: true,
        });
      }
    }
  };

  handleDisclaimerContinue = (fields) => {
    let expiryDate = new Date();
    if (fields.popupDaysToExpire) {
      if (fields.popupDaysToExpire.value !== 0) {
        expiryDate.setTime(
          expiryDate.getTime() +
            fields.popupDaysToExpire.value * 24 * 60 * 60 * 1000,
        );
      }
    } else {
      // Default expiry is 1 month
      expiryDate.setMonth(expiryDate.getMonth() + 1);
    }
    cookie.save("disclaimer", true, {
      path: "/",
      expires: expiryDate,
    });
    this.closeDialogDisclaimerPopup();
  };

  handleDisclaimerCountrySelect = () => {
    this.closeDialogDisclaimerPopup();
    this.toggleCountrySelector();
  };

  handleDisclaimerOpenURL = () => {
    this.closeDialogDisclaimerPopup();
    this.toggleCountrySelector();
  };

  handleScroll = () => {
    const scrollTarget = this.navRef.current;
    if (scrollTarget) {
      this.setState((prevState) => {
        //ie can't usee scrollY or getBoundingClientRect().y, so we have fallbacks
        const boundY = scrollTarget.getBoundingClientRect().y;
        const y = window.scrollY || window.pageYOffset;
        return {
          sticky: prevState.sticky
            ? y !== 0
            : boundY
              ? boundY < -150
              : window.pageYOffset > 150,
        };
      });
    }
  };

  setOverlay = (reactChild = false) => {
    this.setState((prevState) => ({
      overlay: reactChild,
      isSearching: reactChild ? prevState.isSearching : false,
    }));
  };

  setOverlayDisclaimer = (reactChild = false) => {
    this.setState((prevState) => ({
      overlayDisclaimer: reactChild,
      isSearching: reactChild ? prevState.isSearching : false,
    }));
  };

  toggleSearch = () => {
    this.setState((prevState) => {
      return {
        overlay: !prevState.isSearching,
        isSearching: !prevState.isSearching,
      };
    });
    this.props.headerContext.handleCloseMobileMenu();
    this.props.headerContext.setPageScrollable(true);
  };

  closeSearch = () => {
    this.setState({
      overlay: false,
      isSearching: false,
    });
    this.props.headerContext.setPageScrollable(true);
  };

  closeDialogPopup = (redirect = false) => {
    if (redirect) {
      if (typeof redirect !== "boolean") {
        window.location.href = redirect;
      } else {
        window.history.back();
      }
    } else {
      document.body.style = null;
      this.setState({
        overlay: false,
        overlayClick: () => this.setOverlay(),
      });
    }
  };

  closeDialogDisclaimerPopup = (redirect = false) => {
    document.body.style = null;
    this.setState({
      overlayDisclaimer: false,
      overlayClick: () => this.setOverlayDisclaimer(),
    });
  };

  toggleCountrySelector = (shouldShow = null) => {
    this.setState((prevState) => {
      const show =
        shouldShow === null ? !prevState.showCountryDropdown : shouldShow;
      this.props.headerContext.setPageScrollable(!show);
      return {
        overlay: show ? (
          <CountrySelector
            fields={this.props.fields}
            toggleCountrySelector={() => this.toggleCountrySelector()}
          />
        ) : null,
        showCountryDropdown: show,
      };
    });
  };

  toggleClassDropdownNav() {
    // limit scrolling to the profile dropdown
    // when it is active
    // css class used only applies to mobile/tablet
    const element = document.getElementsByTagName("BODY")[0];
    element.classList.toggle("profile-active");
    const { isActiveDropdownNav } = this.state;
    this.setState({
      isActiveDropdownNav: !isActiveDropdownNav,
    });
  }

  // triggered from Profile Dropdown or Pop Over
  toggleFeaturesModal() {
    const element = document.getElementsByTagName("HTML")[0];
    element.classList.toggle("is-clipped");
    const { showFeaturesModal } = this.state;
    this.setState({
      showFeaturesModal: !showFeaturesModal,
    });
  }

  setWhatsNewContent(data) {
    if (data) {
      this.setState({
        featuresModalContent: data,
      });
    }
  }

  // has to update state and pass as prop to
  // ProfileDropdown component so both instances behave the same
  toggleWhatsNewPopOver(bool) {
    this.setState({
      showPopOver: bool,
    });
  }

  render() {
    const {
      overlay,
      overlayDisclaimer,
      overlayClick,
      isSearching,
      sticky,
      isActiveDropdownNav,
      showPopOver,
      profileLoaded,
      showFeaturesModal,
      featuresModalContent,
      supportedCountry,
      currentCountry,
      homePath,
      tokenUserCountryCode,
      tokenUserLocale,
    } = this.state;
    const { fields, headerContext, sitecoreContext, match } = this.props;
    const {
      AUTH_AUTHORIZE,
      COCHLEAR_COOKIE_DOMAIN,
      AUTH_CIM_RECIPIENT_LOGOUT,
      AUTH_REVOKE,
      AUTH_TOKEN,
      SC_CD,
      SC_API_KEY,
      COUNTRY_LANGUAGE_LIST,
      messageBannerText,
      showSearchIcon,
      showTopNav,
      useUniversalHeader,
    } = fields;

    const { handleCloseMobileMenu, handleToggleMobileMenu, showMobileMenu } =
      headerContext;

    const { urlLang } = match.params;

    const isProfessional = sitecoreContext.route.fields.isProfessional.value;

    if (!supportedCountry) {
      return (
        <NotAuthorized
          homePath={homePath}
          cookieDomain={COCHLEAR_COOKIE_DOMAIN}
          authLogout={AUTH_CIM_RECIPIENT_LOGOUT}
          authRevoke={AUTH_REVOKE}
          authToken={AUTH_TOKEN.value}
          scCd={SC_CD.value}
          scApiKey={SC_API_KEY.value}
          routeLocaleCode={sitecoreContext.metadata.localeCode}
          tokenUserCountryCode={tokenUserCountryCode}
          tokenUserLocale={tokenUserLocale}
          countryLanguageList={COUNTRY_LANGUAGE_LIST.value}
        />
      );
    }

    // check if country / language has a login link in the
    const isLoginEnabled = !!fields.loginLink.value.href;
    // check if we want to show the profile dropdown for this site if user is logged in
    const isProfileEnabled = !!(
      useUniversalHeader && useUniversalHeader.value === true
    );

    // update the header login path based on environemnt and whether profile is enabled in the header api
    let loginPath = isLoginEnabled && fields.loginLink.value.href;
    if (isProfileEnabled) {
      loginPath = AUTH_AUTHORIZE.value;
    }

    /**
     * Update store links for countries that share a .com but have their own store
     */
    updateStoreLinks(
      profileLoaded,
      tokenUserCountryCode,
      currentCountry,
      fields,
    );

    const toolbarClass = (searching, mobileMenuOpen) => {
      if (searching) {
        return "";
      }
      return mobileMenuOpen ? "mobile-menu" : "mobile-hidden";
    };
    return (
      <>
        {showFeaturesModal && (
          <ModalNewFeatures
            closeCallback={this.toggleFeaturesModal}
            data={featuresModalContent}
          />
        )}

        <header className={`global-header${sticky ? " is-sticky-header" : ""}`}>
          <ul className="accessibility-links" tabIndex="-1">
            <li>
              <a href="#article" tabIndex={showMobileMenu ? -1 : 0}>
                {fields.jumpToContentText.value}
              </a>
              <a
                href="#nav"
                className="jump-nav-desktop"
                tabIndex={showMobileMenu ? -1 : 0}
              >
                {fields.jumpToNavText.value}
              </a>
              <a
                href="#mobile-toolbar-nav"
                className="jump-nav-mobile"
                tabIndex={showMobileMenu ? -1 : 0}
              >
                {fields.jumpToNavText.value}
              </a>
            </li>
          </ul>
          {messageBannerText.value && (
            <MessageBanner field={messageBannerText} />
          )}

          {/* Show country redirect banner if disclaimer modal is not enabled (DBR-8910) */}
          {sitecoreContext.metadata.contentDisclaimerShow !== "1" && (
            <CountryRedirect
              fields={fields}
              toggleCountrySelector={this.toggleCountrySelector}
            />
          )}

          <MobileToolbar
            fields={fields}
            handleMenuClick={() =>
              this.setState(() => {
                handleToggleMobileMenu();
                return {
                  isSearching: false,
                  overlay: false,
                };
              })
            }
            showMobileMenu={showMobileMenu}
            handleSearchClick={this.toggleSearch}
          />

          <nav
            id="nav"
            ref={this.navRef}
            className={`nav-onscreen js-onscreen ${toolbarClass(isSearching, showMobileMenu)} ${sticky ? "is-sticky" : ""}`}
          >
            {showTopNav && showTopNav.value && (
              <TopNav
                fields={fields}
                urlLang={urlLang}
                toggleCountrySelector={this.toggleCountrySelector}
                isActiveDropdownNav={isActiveDropdownNav}
                showPopOver={showPopOver}
                isProfileEnabled={isProfileEnabled}
                isLoginEnabled={isLoginEnabled}
                loginPath={loginPath}
                isUserLoggedIn={profileLoaded}
                callbackClassDropdownNav={this.toggleClassDropdownNav}
                callbackWhatsNew={this.toggleFeaturesModal}
                callbackWhatsNewContent={this.setWhatsNewContent}
                callbackShowPopOver={this.toggleWhatsNewPopOver}
              />
            )}
            <div className={"l-padding"}>
              {showMobileMenu ? (
                <OnScreenNav
                  fields={fields}
                  isProfessional={isProfessional}
                  handleMobileClose={handleCloseMobileMenu}
                  toggleCountrySelector={this.toggleCountrySelector}
                  closeSearch={this.closeSearch}
                  showMobileMenu={showMobileMenu}
                  isProfileEnabled={isProfileEnabled}
                  isUserLoggedIn={profileLoaded}
                  showLanguageDropdown={showTopNav?.value}
                />
              ) : (
                <>
                  <div className="logo">
                    <ImageLink
                      imageField={fields.desktopLogo}
                      linkField={fields.logoLink}
                    />
                  </div>
                  <OnScreenNav
                    fields={fields}
                    searchOpen={isSearching}
                    closeSearch={this.closeSearch}
                  />
                  {showSearchIcon && showSearchIcon.value && (
                    <button
                      className={`link-icon search-toggle ${isSearching ? "active" : ""}`}
                      type="button"
                      aria-label="Toggle search"
                      data-popover-id="global-search"
                      aria-expanded="false"
                      onClick={this.toggleSearch}
                    />
                  )}
                </>
              )}
            </div>
            {isSearching && <Search fields={this.props.fields} />}
          </nav>

          <MobileHeader
            fields={fields}
            urlLang={urlLang}
            showMobileMenu={showMobileMenu}
            isActiveDropdownNav={isActiveDropdownNav}
            showPopOver={showPopOver}
            isProfileEnabled={isProfileEnabled}
            isLoginEnabled={isLoginEnabled}
            loginPath={loginPath}
            isUserLoggedIn={profileLoaded}
            callbackClassDropdownNav={this.toggleClassDropdownNav}
            callbackWhatsNew={this.toggleFeaturesModal}
            callbackWhatsNewContent={this.setWhatsNewContent}
            callbackShowPopOver={this.toggleWhatsNewPopOver}
          />

          {overlay && (
            <>
              <div
                onClick={overlayClick}
                onKeyDown={BindEnterAndSpaceToClick}
                role="button"
                tabIndex="0"
                className="offscreen-background is-expanded"
              />
              {overlay}
            </>
          )}

          {overlayDisclaimer || null}
        </header>
      </>
    );
  }
}

Header.defaultProps = defaults;
Header.propTypes = types;

export default withSitecoreContext()(withRouter(withHeaderContext(Header)));
