export default function cochlearCountry(countryCode) {
  let code = "";
  let home = "/";
  switch (countryCode) {
    case "KR":
      code = "KR";
      home = "/kr";
      break;

    case "JP":
      code = "JP";
      home = "/jp";
      break;

    case "BE":
      code = "BE";
      home = "/be";
      break;

    case "FR":
      code = "FR";
      home = "/fr";
      break;

    case "NL":
      code = "NL";
      home = "/nl";
      break;

    case "US":
    case "PR":
      code = "US";
      home = "/us";
      break;

    case "CA":
      code = "CA";
      home = "/ca";
      break;
    case "IT":
      code = "IT";
      home = "/it";
      break;
    case "GB":
    case "UK":
    case "IE":
      code = "UK";
      home = "/uk";
      break;
    // ANZ region
    case "AU":
    case "NZ":
      code = "AU";
      home = "/au";
      break;
    // EMEA region
    case "AE": // United Arab Emirates
    case "BH": // Bahrain
    case "DZ": // Algeria
    case "EG": // Egypt
    case "IQ": // Iraq
    case "JO": // Jordan
    case "KW": // Kuwait
    case "LB": // Lebanon
    case "LY": // Libya
    case "MA": // Morocco
    case "OM": // Oman
    case "PS": // Palestine
    case "QA": // Qatar
    case "SA": // Saudi Arabia
    case "SD": // Sudan
    case "TN": // Tunisia
    case "YE": // Yemen
      code = "ME";
      home = "/me";
      break;

    default:
      code = "";
      home = "/";
  }
  return { code, home };
}
