// given a new store url, update the existing one
function updateFields(link, fields) {
  const topNav = fields.topNavList.filter((x) => x.name === 'Store')[0];
  const mobile = fields.additionalList.filter((x) => x.name === 'Store')[0];

  topNav.fields.link.value.href = link;
  topNav.fields.link.value.url = link;
  mobile.fields.link.value.href = link;
  mobile.fields.link.value.url = link;
}

export default function (
  profileLoaded,
  tokenUserCountryCode,
  currentCountry,
  fields,
) {
  // logged in users, update store link on shared sites
  // Eg, NZ uses AU site but has its own NZ store, so we want to change the store link in the main nav to go to NZ store
  if (profileLoaded) {
    // check the users country is different to the currently view cochlear website country
    if (tokenUserCountryCode.toUpperCase() !== currentCountry.toUpperCase()) {
      // does the country library have defined store links for users from different country/countries
      const storeLinksArr = fields.additionalStoreLinks;

      // lets pass the users country code and return the store link for that country
      const storeLinkObj =
        storeLinksArr &&
        storeLinksArr.filter(
          (x) => x.name.toUpperCase() === tokenUserCountryCode.toUpperCase(),
        )[0];

      // get the link
      const storeLink = storeLinkObj && storeLinkObj.fields.link.value.href;
      // update the store links that are passed as props to the main navigational components
      if (storeLink) updateFields(storeLink, fields);
    }
  }
}
