import React from 'react';
import cookie from 'react-cookies';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const DialogPopup = ({ fields, closePopup, redirectUrl, cookieName }) => {
  const handleAccept = () => {
    let expiryDate = new Date();
    if (fields.popupDaysToExpire && fields.popupDaysToExpire.value) {
      expiryDate.setTime(
        expiryDate.getTime() +
          fields.popupDaysToExpire.value * 24 * 60 * 60 * 1000,
      );
    } else {
      // Default expiry is 1 month
      expiryDate.setMonth(expiryDate.getMonth() + 1);
    }
    cookie.save(cookieName, true, { path: '/', expires: expiryDate });
    closePopup();
  };

  let buttonDecline =
    fields.popupDecline && fields.popupDecline.value !== '' ? (
      <button
        type="button"
        className="cta-button secondary"
        onClick={() => closePopup(redirectUrl)}
      >
        <Text field={fields.popupDecline} />
      </button>
    ) : (
      ''
    );

  const modalClassName = `dialog-popup ${
    fields.popupStyle !== undefined ? fields.popupStyle.value : ''
  }`;

  return (
    <div className={modalClassName}>
      <button
        type="button"
        className="close-btn"
        onClick={() => closePopup(redirectUrl)}
        aria-label={fields.popupCloseAria.value}
      />
      <Text field={fields.popupTitle} tag="h2" />
      <Text field={fields.popupDescription} tag="p" />
      <div className="btn-row">
        <button
          type="button"
          className="cta-button primary"
          onClick={handleAccept}
        >
          <Text field={fields.popupAccept} />
        </button>
        {buttonDecline}
      </div>
    </div>
  );
};

export default DialogPopup;
