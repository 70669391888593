import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';

class ModalNewFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.close = this.close.bind(this);
    this.next = this.next.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  close() {
    // call parent function to update prop
    this.props.closeCallback();
  }

  render() {
    const { data } = this.props;

    const slides = data.content;
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="nf-modal is-active">
        <div className="nf-modal__background" />
        <div className="nf-modal__content">
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {slides.map((slide) => (
              <div key={slide.title} className="slide">
                <figure className="slide__figure">
                  <img src={slide.image.href} alt={slide.image['alt-text']} />
                </figure>
                <h2 className="slide__title">{slide.title}</h2>
                <p className="slide__text">{slide.text}</p>
                <button
                  className={`slide__button slide__button--${
                    slide.button.style
                  } ${slide.button.action !== 'next' ? 'no-icon' : ''}`}
                  type="button"
                  onClick={this[slide.button.action]}
                >
                  {slide.button.text}
                </button>
              </div>
            ))}
          </Slider>
          <button className="nf-modal__close-btn--text" onClick={this.close}>
            <span className="icon--delete" />
            {data.closeBtn}
          </button>
          <button
            className="nf-modal__close-btn"
            aria-label="close"
            onClick={this.close}
          />
        </div>
      </div>
    );
  }
}

ModalNewFeatures.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default withRouter(ModalNewFeatures);
