import React from 'react';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    this.searchRef.current.focus();
  }

  render() {
    const { fields } = this.props;
    return (
      <div className="search-box" role="search">
        <div className="l-padding">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              window.location.replace(
                `${fields.searchLink.value.href}?q=${this.searchRef.current.value}`,
              );
            }}
          >
            <label className="vh" htmlFor="q">
              Search
            </label>
            <p className="vh">{fields.searchDescription.value}</p>
            <input
              name="q"
              id="q"
              className="search-input"
              type="search"
              ref={this.searchRef}
              autoComplete="off"
              onChange={(e) => {
                e.preventDefault();
                this.setState({
                  searchInput: this.searchRef.current.value,
                });
              }}
              placeholder={fields.searchPlaceholder.value}
            />
            <span className="underline" tabIndex="-1">
              {this.state.searchInput}
            </span>
            <button className="search-trigger" type="submit" />
          </form>
        </div>
      </div>
    );
  }
}

export default Search;
