import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * Renders modal pop up configured by values in 'fields', and binded by functions in 'actions'
 * @param {object} fields Contains child values as per below
 * @param {string} fields.Style: disclaimerData.contentDisclaimerDataSource.fields.modalStyle,
 * @param {object.string} fields.Title: { value: string }, Modal title
 * @param {object.string} fields.Description: { value: string }, Modal body text
 * @param {object.string} fields.Primary:  { value: string }, Primary button label text
 * @param {object.string} fields.Secondary: { value: string }, Secondary button label text
 * @param {object.string} fields.CloseAria: { value: string}, Aria tag label
 * @param {object.boolean} fields.popupShowDialog: { value: boolean}, (currently not active) Setting that enables or disables popup
 * @param {object} actions Contains child functions as per below
 * @param {function} handlePrimary function to bind to primary button
 * @param {function} handleSecondary function to bind to secondary button
 * @param {function} handleClose: function to bind to close button
 * @returns
 */
const ModalGlobalComponent = ({ fields, actions }) => {
  let buttonSecondary =
    fields.popupSecondary && fields.popupSecondary.value !== '' ? (
      <button
        type="button"
        className="cta-button secondary"
        onClick={() => actions.handleSecondary(fields)}
      >
        <Text field={fields.popupSecondary} />
      </button>
    ) : (
      ''
    );

  const modalClassName = `modal-global ${
    fields.popupStyle !== undefined ? fields.popupStyle : ''
  }`;

  return (
    <React.Fragment>
      <div className={modalClassName}>
        <button
          type="button"
          className="close-btn"
          onClick={() => actions.handleClose()}
          aria-label={fields.popupCloseAria.value}
        />
        {/* <Text field={fields.popupTitle} tag="h2" /> */}
        {}
        <Text field={fields.popupDescription} tag="p" />
        <div className="btn-row">
          <button
            type="button"
            className="cta-button primary"
            onClick={actions.handlePrimary}
          >
            <Text field={fields.popupPrimary} />
          </button>
          {buttonSecondary}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalGlobalComponent;
