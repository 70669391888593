import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const MessageBanner = ({ field }) => {
  return (
    <div className="message-banner">
      <RichText field={field} className="l-padding" />
    </div>
  );
};

export default MessageBanner;
