import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import cookie from 'react-cookies';

class CountryRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.select = React.createRef();
    this.state = {
      countryOption: null,
    };
  }

  async componentDidMount() {
    const { currentCountry, regionList } = this.props.fields;
    // console.log("============> currentCountry = ", currentCountry.fields.key.value);

    const onSuccess = (res) => {
      const countryCookie = cookie.load('countrySelectorDropDown');
      let countryIsoCode = res.country.iso_code.toLowerCase();
      // console.log("============> countryIsoCode = ", countryIsoCode);
      const isSameLocation = currentCountry.fields.key.value === countryIsoCode;
      // console.log("============> isSameLocation = ", isSameLocation);

      if (
        !isSameLocation &&
        (!countryCookie || !countryCookie.includes(countryIsoCode))
      ) {
        let matchingCountry = null;
        regionList.map((region) => {
          region.fields.countryList.map((country) => {
            if (country.fields.key.value === countryIsoCode) {
              matchingCountry = country;
            }
          });
        });
        // console.log("============> matchingCountry = ", matchingCountry);
        this.setState({
          countryOption: matchingCountry,
          geoIpRegion: countryIsoCode,
        });
      }
    };

    const onError = () => {
      return null;
    };

    if (window && window.geoip2) {
      window.geoip2.country(onSuccess, onError);
    } else {
      return null;
    }
  }

  closeCountryRedirect = () => {
    const { geoIpRegion } = this.state;
    const countryCookie = cookie.load('countrySelectorDropDown');
    const cookieArray = countryCookie
      ? [...countryCookie, geoIpRegion]
      : [geoIpRegion];
    cookie.save('countrySelectorDropDown', JSON.stringify(cookieArray), {
      path: '/',
      secure: true,
    });
    this.setState({ countryOption: null });
  };

  render() {
    const { fields, toggleCountrySelector } = this.props;
    const {
      countryRedirectText,
      countryRedirectRegionText,
      countryRedirectButton,
      countryRedirectClose,
    } = fields;
    const { countryOption } = this.state;
    return countryOption ? (
      <div className="country-redirect">
        <div className="l-padding">
          <div className="content-container">
            <Text field={countryRedirectText} tag="p" />
            <div className="dropdown-container form-dropdown-box">
              <select ref={this.select} className="select dropdown">
                <option value={countryOption.fields.link.value.href}>
                  {countryOption.fields.text.value}
                </option>
                <option value="select-country">
                  {countryRedirectRegionText.value}
                </option>
              </select>
              <button
                className="cta-button secondary"
                type="button"
                onClick={() => {
                  this.select.current.value === 'select-country'
                    ? toggleCountrySelector(true)
                    : (window.location.href = this.select.current.value);
                }}
              >
                <Text field={countryRedirectButton} />
              </button>
              <button
                className="close-button"
                type="button"
                onClick={() => this.closeCountryRedirect()}
                aria-label={countryRedirectClose.value}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default CountryRedirect;
