import React from "react";

const ListItem = ({ path, icon, target, label }) => {
  return (
    <a
      className={`profile-dropdown__menu__item ${
        icon === "external" ? "profile-dropdown__menu__item--external" : ""
      }`}
      href={path}
      target={target || "_self"}
    >
      {label}
    </a>
  );
};

export default ListItem;
